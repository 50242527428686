<template>
	<div v-if="hasPermission">
		<ContentIncomeStatement />
	</div>
</template>

<script>
// @ is an alias to /src
import ContentIncomeStatement from '@/components/content/content-acc/ContentIncomeStatement.vue'
import { getPermissions } from '../components/util/liblist'

export default {
	name: 'IncomeStatement',
	components: {
		ContentIncomeStatement,
	},
	data() {
		return {
			'hasPermission': false, 
		}
	},
	mounted() {
		if (getPermissions('accounting.report.incomestatement')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
